import '../../../scss/security/inscription_auth.scss';
import * as React from 'react';
import * as Modal from 'react-modal';
import { DescriptionBlock } from "./components/descriptionBlock";
import { FormBlock } from "./components/formBlock";
import { SuccessBlock } from "./components/successBlock";
import { FormBlockMode } from "./enums";
import { useMemo } from "react";
import { translate } from "../helpers";
import { useAuth } from "./hooks/useAuth";
import { AuthContext } from './contexts';
var AuthModal = function (_a) {
    var registrationSource = _a.registrationSource;
    var auth = useAuth(registrationSource);
    var body = document.querySelector('body');
    if (!body) {
        return null;
    }
    Modal.setAppElement(body);
    var Close = (React.createElement("button", { className: "btn", onClick: function () { return location.reload(); } }, translate('auth.form.type.create_account.action.explore_tagwalk')));
    var successScreen = useMemo(function () {
        return React.createElement("div", { className: "auth-body" },
            React.createElement("div", { className: "success-block" },
                React.createElement(SuccessBlock, { action: Close })));
    }, [auth.isAuthModalOpen]);
    var formScreen = (React.createElement("div", { className: "auth-body modal-body" },
        React.createElement(DescriptionBlock, null),
        React.createElement("div", { className: "right-block" },
            React.createElement(FormBlock, { mode: FormBlockMode.Toggle }))));
    return (React.createElement(AuthContext.Provider, { value: auth },
        React.createElement(Modal, { isOpen: auth.isAuthModalOpen, className: "tds", overlayClassName: "login-modal-overlay" },
            React.createElement("div", { id: "authModalContent", className: "modal modal--open" }, auth.registrationStep < auth.maxStepCount ? formScreen : successScreen))));
};
export { AuthModal };
